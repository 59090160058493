// 
// tables.scss
//


//Table centered (Custom)
.table-centered {
    td,th {
        vertical-align: middle !important;
    }
}


// Custom table components (Custom)
.content {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  &._table {
    height: calc(100vh - 3.25rem);
    //min-height: 736px;
    padding-bottom: 3.25rem;
    position: relative;
  }

  &__container {
    height: 100%;
    margin: 0;
    overflow: auto;

    &._full-size {
      position: fixed;
      left: 0;
      top: 0;
      z-index: 999999;
      margin-top: 0 !important;
      width: 100%;
      padding-bottom: calc(4.5rem + 10px);
    }
  }

  &._table &__footer {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding-top: 1.25rem;
    height: 2rem;
    box-sizing: content-box;
  }

  &__container._full-size &__footer {
    background: var(--dropdown-link-hover-bg);
    justify-content: flex-end;
    position: fixed;
    padding: 1.25rem;
    border-top: 10px solid var(--body-bg);
  }
}

.table {
    color: var(--table-color);
    background-color: var(--table-bg);
    margin: 0;

    .table-user {
        img {
            height: 30px;
            width: 30px;
        }
    }
    tbody {
        tr {
            &:hover {
                background-color: var(--table-hover-bg);
            }
        }
    }
  thead {
    tr {
      th {
        &.kws-bg-white {
          background-color: var(--table-tr-bg);
        }
      }
    }
  }
}

.product-table {
  &:only-child {
    margin: 0;
  }

  .td-break {
    border-right: 15px var(--body-bg) solid;
    &:last-child {
      border-right: none;
    }
  }

  thead {
    position: sticky;
    top: 0;
    z-index: 2;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      width: 100%;
      height: calc(100% + 6px);
      background: var(--body-bg);
    }
  }

  &__month {
    th {
      //border-left: 10px solid var(--body-bg) !important;
    }
  }

  th {
    text-align: center;
    //min-width: 138px;

    &._first-cell {
      position: sticky;
      left: 0;
      background: var(--table-tr-bg) !important;
      padding: 0 0.5rem !important;
    }
  }

  td, th {
    vertical-align: middle;
    .td-row {
      display: flex;
      justify-content: center;
      border-right: 1px #98a6ad solid;
      padding: 0 5px;

      .td-row-left {
        text-align: end;
        width: 100%;
      }
      .td-row-right {
        text-align: start;
        vertical-align: top;
        color: #98a6ad;
        font-size: 10px;
        margin-left: 2px;
        width: 50px;
      }
    }
    &.td-break {
      .td-row {
        border-right: none;
      }
    }
    .td-text {
      font-size: 10px;
      color: #98a6ad;
      text-align: center;
    }
  }

  .table &__cell,
  &__cell {
    background: var(--table-tr-bg);
    border-top: 10px solid var(--body-bg);
    text-align: right;
    white-space: nowrap;

    &._first-cell {
      border-left: none;
      text-align: center;
      vertical-align: middle;
      position: sticky;
      left: 0;
      padding: 0;
      background: var(--body-bg);

      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 100%;
        bottom: 0;
        width: 10px;
        background: var(--body-bg);
      }
    }

    &:last-child {
      border-right: none;
    }

    &._empty {
      background: var(--body-bg);
    }

    &._first-top-cell {
      border-top: none;
    }

    &._th {
      text-align: center;
      &.t-end {
        text-align: end;
        font-size: 13px;
        font-weight: 600;
        white-space: nowrap;
      }
    }

    &._th-asin {
      width: 150px;
    }

    &.cursor-pointer {
      &:hover {
        background: $gray-300;
      }
    }
  }

  &__info {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 0.5rem;
    width: 100%;
    background: var(--table-tr-bg)
  }

  &__text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 114px;
    min-width: 85px;

    &:not(:first-child) {
      margin-top: 0.6rem;
    }

    .text-muted {
      div {
        &:not(:first-child) {
          margin-top: 0.6rem;
        }
      }
    }
  }

  &__value {
    display: flex;
    flex-direction: column;
    margin-bottom: 0.275rem;
    align-items: flex-end;

    div {
      &:not(:first-child) {
        margin-top: 0.6rem;
      }
    }

    .value-title {
      font-size: 11px;
      color: #98a6ad;
    }
  }
}

.action-icon {
    color: $gray-600;
    font-size: 1.2rem;
    display: inline-block;
    padding: 0 3px;

    &:hover {
        color: $gray-700;
    }
}
