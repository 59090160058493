//
// reboot.scss
//
body {
  background-color: var(--body-bg);
  color: var(--body-color);
}

// Forms
label {
  font-weight: $font-weight-semibold;
}


button:focus {
  outline: none;
}