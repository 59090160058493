body {
  &.dark-mode {
    $custom-select-background: #fff;
    $custom-select-indicator-color: $white;

    $theme-colors: (
      "primary": $black,
      "light": $black,
      "body-bg": $body-bg-dark,
      "body-color": $white,
      "right-bar-color": $gray-800,
      "bg-leftbar": $bg-leftbar-dark,
      "menu-item": $white,
      "custom-select-bg": $dark-800,
      "custom-select-color": $white,
      "input-color": $white,
      "input-bg": $dark-800,
      "input-border": rgba($white, 0.1),
      "input-focus-color": $white,
      "input-focus-bg": $dark-800,
      "table-color": $white,
      "table-head-bg": $gray-800,
      "table-head-color": $white,
      "table-tr-bg": $bg-leftbar-dark,
      "table-tr-shadow": $table-tr-shadow-dark,
      "dropdown-bg": $gray-800,
      "dropdown-link-color": $white,
      "dropdown-link-hover-bg": $gray-900,
      "modal-content-bg": $dark-900,
      "modal-content-color": $white,
      "headings-color": $white,
      "card-box": $bg-leftbar-dark,
      "table-hover-bg": #000,
      "input-disabled-bg": $gray-800,
      "menu-item-hover-bg": $menu-item-hover-bg-dark,
      "bg-topbar-bg": $bg-topbar-dark,
      "icon-color": #a8afc7,
    );

    @each $color, $value in $colors {
      --#{$color}: #{$value};
    }

    @each $color, $value in $theme-colors {
      --#{$color}: #{$value};
    }

    @each $bp, $value in $grid-breakpoints {
      --breakpoint-#{$bp}: #{$value};
    }
  }
}
