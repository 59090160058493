.chart {
  &__content {
    height: 134px;
    display: grid;
    grid-template-columns: 160px 1fr;
    grid-gap: 16px;

    @media screen and (max-width: 1200px) {
      grid-template-columns: 160px;
      justify-content: center;
    }
  }

  &__legend {
    overflow: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 12px;
    padding: 0 5px;
    color: #615E59;

    @media screen and (max-width: 1200px) {
      display: none;
    }
  }

  &__item {
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 5px;

    &:last-child {
      margin: 0;
    }

    &._top {
      font-weight: 700;
      border-bottom: 0.5px solid rgba(127, 134, 155, .5);
      padding: 0 0 4px 20px;
    }
  }

  &__type {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-right: 0.75rem;
  }

  &__text {
    margin-left: auto;
    display: flex;
    justify-content: flex-end;
    text-align: right;
  }

  &__text-value {}

  &__text-percent {
    margin-left: 5px;
    width: 54px;
  }

  &__color {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    margin-right: 5px;
  }
}
