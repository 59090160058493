$errorColor: #f1556c;
$disabledColor: #e9ecef;

hr {
  margin: 0;
  border-top: 1px solid #dee2e6;
}

.input-field.has-error {
  border-color: $errorColor;
}

.cursor-pointer {
  cursor: pointer;
}

.hidden {
  display: none !important;
}

.dropdown-toggle-split {
  &:after {
    display: inline-block;
    /*margin-left: .255em;*/
    vertical-align: .255em;
    content: "";
    border-top: .3em solid;
    border-right: .3em solid transparent;
    border-bottom: 0;
    border-left: .3em solid transparent;
    margin-left: 0;
  }
}

.enlarged .left-side-menu {
  width: 65px !important;
}

.form-group {
  margin: 0;
}

.form-group[required] > label:after {
  content: "*";
  color: $errorColor;
}

.markdown-viewer {
  $text-color: #595959;
  $line-height: 1.5;

  font-size: $font-size-base !important;
  font-family: $font-family-base !important;

  .h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    margin: 0 0 10px !important;
    font-weight: $font-weight-medium !important;
    color: #393939 !important;
    line-height: $line-height !important;
  }

  h1 {
    font-size: 1.125rem !important;
  }

  h2 {
    font-size: 0.9375rem !important;
  }

  h3, h4, h5, h6 {
    font-size: 0.75rem !important;
  }

  p {
    margin-top: 0 !important;
    margin-bottom: 1rem !important;
  }

  p, ul, ol, table {
    color: $text-color !important;
  }

  a {
    color: #00b153 !important;
  }

  p, ol, ul {
    line-height: $line-height !important;
  }

  table {
    width: 100% !important;

    th {
      border: 1px solid #ececec !important;
      background-color: #cfcfcf !important;
      color: $text-color !important;
    }
  }
}

.b-tooltip.tooltip-white {
  opacity: 1;

  &[x-placement="right"] {
    .arrow {
      &::before {
        border-right-color: #fff;
      }
    }
  }

  &[x-placement="left"] {
    .arrow {
      &::before {
        border-left-color: #fff;
      }
    }
  }

  &[x-placement="top"] {
    .arrow {
      &::before {
        border-top-color: #fff;
      }
    }
  }

  &[x-placement="bottom"] {
    .arrow {
      &::before {
        border-bottom-color: #fff;
      }
    }
  }

  .tooltip-inner {
    color: var(--modal-content-color);
    background-color: var(--modal-content-bg);
    box-shadow: 0 0 0.50rem rgba(0, 0, 0, 0.2);
    max-width: 400px;
  }
}

.payment-system-logo {
  height: 18px;
}

.disabled-input {
  .form-control {
    background-color: $disabledColor;
    opacity: 1;
  }
}

.form-control {
  &:focus {
    border-color: $menu-item-hover;
  }

  &:disabled {
    background-color: $disabledColor;
    opacity: 1;
  }
}

.content-page {
  margin-top: 0;
}

.left-side-menu {
  top: 0;
}

div.v--modal-overlay {
  background: rgba(20, 20, 47, .6);
}

div.v--modal,
.modal-content {
  color: var(--modal-content-color);
  background-color: var(--modal-content-bg);
}

.modal-header {
  justify-content: flex-start;
  align-items: center;

  &__submit {
    margin-left: auto;
  }

  &__cancel {
    font-size: 18px;
    padding: 4px 10px;
    margin: 0 10px 0 -10px;
  }
}

.dialog-c-text {
  overflow: auto;
}

body pre {
  color: var(--body-color);
}

.vue-dialog {
  div.dialog-c-title {
    font-size: 0.9375rem;
    margin: 10px 0;
    padding: 0;
    font-weight: 500;
    font-family: "Nunito", sans-serif;
    color: var(--headings-color);
    text-align: center;
  }

  .dialog-c-text {
    max-height: calc(80vh);
  }
}

.input-group .input-group-text {
  background-color: $primary;
  border-color: $primary;
  color: $white;

  &:hover {
    color: #fff;
    background-color: rgba($primary, 0.8);
    border-color: rgba($primary, 0.8);
  }
}

div.tui-editor-defaultUI {
  color: var(--input-color);
  border: 1px solid var(--input-border);
  border-radius: 5px;
}

div.te-toolbar-section {
  border: none;
}

div.tui-editor-defaultUI-toolbar {
  background-color: var(--input-bg);
  border: none;
  border-bottom: 1px solid var(--input-border);

  button {
    border-radius: 5px;
  }
}

div.tui-editor {
  color: var(--input-color);
}

div.tui-editor-contents p {
  color: var(--input-color);
}

div.te-ww-container {
  border: none;
  background-color: var(--input-bg);
}

div.tui-toolbar-divider {
  background: var(--input-border);
}

div.tui-popup-wrapper {
  background-color: var(--input-bg);
  border-bottom: 1px solid var(--input-border);
}

div.b-toast.b-toast-solid .toast,
div.toast {
  background: var(--modal-content-bg);
  color: var(--input-color);
  box-shadow: $shadow-dark;

  button.close {
    color: var(--input-color);
  }
}

.toast-header {
  background: var(--input-bg);
  border-bottom: 1px solid var(--input-border);
}

.font-11 {
  font-size: 11px;
}
