//
// custom-select.scss
//

[data-plugin="customselect"] {
    display: none;
}

.nice-select {
    line-height: 36px;
    height: 38px;
    border-color: $input-border-color;
    border-radius: $input-border-radius;
}

.nice-select.open, .nice-select:active, .nice-select:focus {
    border-color: $input-focus-border-color;
}

.nice-select.small {
    height: 32px;
    line-height: 30px;
}

.custom-select {
    background: var(--custom-select-bg);
    color: var(--custom-select-color);
    border-color: var(--input-border);
    position: relative;

    &:focus {
        border-color: var(--input-border);
    }

    &.is-valid, &.is-invalid {
        background-color: var(----custom-select-bg);
    }

    body.dark-mode & {
        background: escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'><path fill='#fff' d='M2 0L0 2h4zm0 5L0 3h4z'/></svg>")) right $custom-select-padding-x center / $custom-select-bg-size no-repeat var(--custom-select-bg);
    }

    body:not(.dark-mode) & {
        background: escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'><path fill='#343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/></svg>")) right $custom-select-padding-x center / $custom-select-bg-size no-repeat var(--custom-select-bg);
    }
}
