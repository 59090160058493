.grid-table {
  overflow-x: auto;
  border-radius: 24px;

  [class*="table-responsive-"] {
    margin-bottom: 0;
  }

  &__footer {
    overflow: hidden;
  }

  &.row-clickable {
    tbody {
      tr {
        cursor: pointer;
      }
    }
  }

  .b-table-sticky-header {
    th {
      background-color: var(--table-tr-bg);
    }
  }

  .table.b-table {
    &.table-hover {
      > tbody > tr:hover > .table-b-table-default {
        background-image: none;
      }

      &.table-dark {
        > tbody > tr:hover > .bg-b-table-default {
          background-image: none;
        }
      }
    }
  }

  table {
    height: 100%;
    font-weight: 400;
    font-size: 13px;
    border-spacing: 0;
    border-collapse: separate;

    tr {
      background-color: var(--table-tr-bg);
      box-shadow: 0 3px 9px 0 var(--table-tr-shadow);
      &.b-table-details {
        .grid-table {
          table {
            border-spacing: 0;
          }
        }
      }
    }

    thead {
      &.hidden-header {
        display: none;
      }

      th {
        vertical-align: middle;

        &.b-table-sort-icon-left {
          background-image: none !important;
          padding-left: 0.85rem !important;
        }

        .sortable {
          border-bottom: 1px dashed #cecece;
          padding: 3px 0;
        }
      }
    }

    tbody {
      tr {
        &.b-table-details {
          td {
            //white-space: unset;
          }

          .detail-block {
            //width: calc(100vw - 368px);

            .enlarged & {
              //width: calc(100vw - 148px);
            }
          }

          .grid-table {
            overflow-x: hidden;
          }
        }

        td {
          vertical-align: middle;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;

          &.no-wrap-disable {
            white-space: unset !important;
            max-width: 0;
            width: 100%;
          }

          &.cell-visible {
            overflow: visible;
          }

          &.action {
            overflow: visible;
          }

          &.b-table-sticky-column {
            background-color: var(--table-tr-bg);
          }
        }

        &:hover {
          background-color: var(--table-tr-bg);
        }
      }
    }
  }

  .b-pagination {
    li[role="separator"] {
      .page-link {
        border: none;
        background-color: #f5f6f8;
      }
    }
  }

  .hidden {
    display: none;
  }

  .pagi-left-block {
    @media screen and (max-width: 768px) {
      margin-bottom: 0.75rem;
    }
  }
}
