// 
// preloader.scss
//

.preloader {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255,255,255,0.8);
    z-index: 9999;
}

.status {
    width: 40px;
    height: 40px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin: -20px 0 0 -20px;
}

.spinner {
    margin: 0 auto;
    font-size: 10px;
    position: relative;
    text-indent: -9999em;
    border-top: 5px solid $gray-300;
    border-right: 5px solid $gray-300;
    border-bottom: 5px solid $gray-300;
    border-left: 5px solid $primary;
    transform: translateZ(0);
    animation: SpinnerAnimation 1.1s infinite linear;
}

.spinner,
.spinner:after {
    border-radius: 50%;
    width: 40px;
    height: 40px;
}

/* COLORS */
$black: #444;
$brown: #725043;
$d-brown: #604338;
$d-grey: #A5998C;
$grey: #AFA193;
$l-grey: #DDD4CC;
$white: #FCF3EA;
$red: #AF442F;
$dark: #5F5449;
$orange: #C98226;
$d-orange: #AF7221;
$twig: #4F3931;

/* MIXINS */
@mixin pseudo ($position: absolute, $display: block, $content: '') {
	position: $position;
	display: $display;
	content: $content;
}

@mixin size($height, $width) {
	height: $height;
	width: $width;
}

@mixin cross($property, $val) {
	-webkit-#{$property}: $val;
	-moz-#{$property}: $val;
	-ms-#{$property}: $val;
	#{$property}: $val;
}

.owl-wrapper {
	transform: none !important;
	height: 100px;
    display: flex;
    align-items: center;
    overflow: hidden;
}

.owl {
	position: relative;
    display: flex;
    align-items: center;
    transform: scale(0.4);
    margin: 0 auto;
	@include size(300px, 300px);
}

.owl-circle {
	position: absolute;
	top: 50px;
	right: 50px;
	bottom: 50px;
	left: 50px;
	border-bottom: 4px solid $header-color;
	border-right: 4px solid $header-color;
	border-radius: 50%;
	animation: rotateEye 1s infinite linear;
}

.owl-mouse {
	width: 50px;
    transform: rotate(62deg) scale(1, -1);
    position: absolute;
    bottom: 32px;
    left: -25px;
    fill: $header-color;
}

%ears {
	@include pseudo;
	top: -8%;
	@include size(40px,30px);
	background: $brown;
}

.ears {
	&::before {
		@extend %ears;
		left: 10%;
		border-radius: 0 90% 0 0;
	}
	&::after {
		@extend %ears;
		right: 10%;
		border-radius: 90% 0 0 0;
	}
}

%hair {
	@include pseudo;
	border-bottom: 15px solid $brown;
	border-left: 10px solid transparent;
	border-right: 10px solid transparent;
}

.head {
	position: absolute;
    top: 10px;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
	@include size(35%,45%);
	background: $brown;
	border-radius: 50%;
	z-index: 2;
	&::before {
		@extend %hair;
		top: -10px;
		left: calc((100% - 20px) / 2);
	}
	&::after {
		@extend %hair;
		top: -5px;
		left: 35%;
	}
}
%eyes-outer {
	@include pseudo;
	top: 16%;
	background: $grey;
	@include size(70px,70px);
	border-radius: 50%;
}
%eyes {
	position: absolute;
	top: 20%;
	background: #fff;
	@include size(60px,60px);
	border-radius: 50%;
	z-index: 3;
}
%inner-eye {
	@include pseudo;
	top: 36%;
	@include size(26px,26px);
	background: $black;
	border-radius: 50%;
}

%light {
	@include pseudo;
	top: 52%;
    left: 32%;
	@include size(6px,6px);
	background: #fff;
	border-radius: 50%;
}

.eyes {
	&::before {
		@extend %eyes-outer;
		left: 4%;
	}
	&::after {
		@extend %eyes-outer;
		right: 4%;
	}
	.left {
		@extend %eyes;
		left: 7%;
		animation: rotateEye 1s infinite linear;

		&::before {
			@extend %inner-eye;
			left: 16%;
		}
		&::after {
			@extend %light;
		}
	}

	.right {
		@extend %eyes;
		right: 7%;
		animation: rotateEye 1s infinite linear;

		&::before {
			@extend %inner-eye;
			right: 40%;
		}
		&::after {
			@extend %light
		}
	}
}

%side-fluff {
	@include pseudo;
	top: -5px;
	border-top: 8px solid transparent;
	border-bottom: 8px solid transparent;
	border-radius: 0;
}

.beak {
	position: absolute;
	bottom: 20%;
	left: calc((100% - 20px) / 2);
	background: $orange;
	@include size(25px, 20px);
	border-radius: 0 0 50% 50%/100%;
	z-index: 2;

	&::before {
		@extend %side-fluff;
		left: -63px;
		border-right: 10px solid $brown;
	}
	&::after {
		@extend %side-fluff;
		right: -63px;
		border-left: 10px solid $brown;
	}
}

@keyframes rotateEye {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

@-webkit-keyframes SpinnerAnimation {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes SpinnerAnimation {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}