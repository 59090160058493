
.navbar-custom {
  position: relative;
  display: none;
  background-color: var(--modal-content-bg);
  box-shadow: none;
  z-index: 1;

  .button-menu-mobile {
    color: #6e768e;
    width: 45px;
  }

  @media screen and (max-width: 767.98px) {
    display: block;
    height: 0;
    position: absolute;

    .button-menu-mobile {
      color: #6e768e;
    }
  }
}

.sidebar-enable {
  .button-menu-mobile {
    color: #6e768e;
    background-color: var(--modal-content-bg);
  }
}
