//
// Daterange.scss
//

div.daterangepicker {
    border: none;
    font-family: $font-family-base;
    color: var(--modal-content-color);
    background-color: var(--modal-content-bg);
    box-shadow: $shadow-dark;
    border-radius: 6px;

    &::after,
    &::before {
        border-bottom-color: var(--modal-content-bg);
    }

    &.show-ranges .drp-calendar.left {
        border: none;
        padding: 8px;
    }

    & .calendar-table {
        background: none;

        thead {
            th {
                border-bottom: 1px solid var(--input-border);
                vertical-align: top;

                &:hover {
                    border-bottom: 1px solid var(--input-border);
                }

                &.next,
                &.prev {
                    position: relative;
                    border: none;
                    background: none;
                    color: $white;

                    &:hover {
                        color: #fff;
                        background: none;
                    }

                    &::before {
                        content: "";
                        position: absolute;
                        top: 2px;
                        right: 0;
                        left: 0;
                        margin: auto;
                        width: 100%;
                        height: 29px;
                        background-color: $primary;
                        border-radius: 5px;
                    }

                    &:hover::before {
                        background: #4938d7;
                    }

                    span {
                        border-color: #fff;
                        position: relative;
                        top: 2px;
                    }
                }

                .row {
                    margin-bottom: 10px;
                }
            }
        }

        tbody {
            th {
                border: 1px solid var(--input-border);
            }
        }

        th,
        td {
            background: none;

            &.in-range {
                color: var(--modal-content-color);
                background: var(--menu-item-hover-bg);
                border-radius: 0;
                border: 1px solid var(--input-border);

                &.active {
                    background: var(--primary);
                    color: #fff;
                }

                &.today {
                    //background: var(--blue);
                    //color: #fff;
                }
            }
        }

        td {
            border: 1px solid var(--input-border);
        }
    }

    &.ltr .drp-calendar.right .calendar-table,
    &.ltr .drp-calendar.left .calendar-table {
        border-radius: 4px;
        padding: 0;
        border: none;
    }

    .ranges li {
        &:hover {
            color: $menu-item-hover;
            background: var(--menu-item-hover-bg);
        }
    }

    .yearselect,
    select.monthselect,
    select {
        color: var(--input-color);
        background-color: var(--input-bg);
        border: 1px solid var(--input-border);
        border-radius: 5px;

        &:focus {
            border: 1px solid var(--input-border);
            outline: none;
        }
    }

    td.active,td.active:hover,.ranges li.active {
        background-color: $primary;
    }
}